<template>
  <div class="disposeComplain bgFFF paddingLR20 paddingT20">
    <titleBox title="客诉信息"
              :status="status">
      <InfoTable :tableData="complainTable"
                 :dataValue="complainData"
                 :num='3' />
    </titleBox>

    <titleBox title="停车信息">
      <div style="margin: 0 0 30px 20px">
        <el-button type="primary"
                   @click="cale()"
                   :loading="loading">计费计算器</el-button>
      </div>
      <div class="tableWrapper">
        <el-table header-cell-class-name="header-call-style"
                  border
                  v-loading="loading"
                  
                  :data="tableData"
                  style="width: 100%;">
          <el-table-column :prop="item.prop"
                           :label="item.label"
                           :width="item.width"
                           v-for="item in tableCols"
                           :key="item.prop"
                           :formatter="item.formatter"
                           align="center"></el-table-column>
        </el-table>
      </div>
    </titleBox>
    <cale ref="cale" />

    <titleBox title="处理历史">
      <div class="disHistory">
        <el-table :data="historyData">
          <el-table-column v-for="item in historyCols"
                           :key="item.label"
                           :property="item.prop"
                           :label="item.label"
                           :width="item.width"></el-table-column>
        </el-table>
      </div>
    </titleBox>

    <el-button type="primary"
               @click="$router.go('-1')"
               :loading="loading"
               style="margin: 0 auto; display: block;">返回</el-button>
  </div>
</template>
<script>
import titleBox from "./components/title";
import cale from "./components/cale";
import InfoTable from '@/components/infoTable/infoTable';
export default {
  components: {
    titleBox,
    cale,
    InfoTable
  },
  data() {
    return {
      historyData: [],
      historyCols: [
        {
          prop: "createTime",
          label: this.$t("list.processing_time"),
          width: ""
        },
        {
          prop: "operationName",
          label: this.$t("list.Processing_personnel"),
          width: ""
        },
        {
          prop: "cityOrderNo",
          label: "市级平台订单号",
          width: ""
        },
        {
          prop: "strClassifyOne",
          label: "问题一级分类",
          width: ""
        },
        {
          prop: "strClassifyTwo",
          label: "问题二级分类",
          width: ""
        },
        {
          prop: "content",
          label: "处理方式",
          width: ""
        }
      ],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: ""
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: ""
        },
        {
          prop: "parkType",
          label: this.$t("list.charge_type"),
          width: "",
          formatter: (row, column) => {
            let obj = {
              "1": "一类区",
              "2": "二类区",
              "3": "三类区"
            };
            return obj[row.parkType];
          }
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: ""
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: ""
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: ""
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: ""
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          }
        }
      ],
      tableData: [
        {
          name: '车牌号',
          key: 'plateNumber'
        }, {
          name: '停车场名称',
          key: 'parkName'
        }, {
          name: '泊位号',
          key: 'berthCode'
        }, {
          name: '入场时间',
          key: 'entryTime'
        }, {
          name: '出场时间',
          key: 'exitTime'
        }, {
          name: '停车时长',
          key: 'parkTime'
        }, {
          name: '计费类型',
          key: 'parkType',
          format: (dataObj, data, dataVal) => {
            
          }
        }, {
          name: '应收金额',
          key: 'shouldPay',
          format: (dataObj, data, dataVal) => {
            return dataVal.shouldPay / 100
          }
        }, {
          name: '入场设备类型',
          key: 'entryDataSource',
          format: (dataObj, data, dataVal) => {

          }
        }, {
          name: '出场设备类型',
          key: 'exitDataSource',
          format: (dataObj, data, dataVal) => {

          }
        }
      ],
      loading: false,
      disposeOP: {},
      complainTable: [
        {
          name: '客诉来源',
          key: 'dataSource',
          format: function (calVal, colObj, dataVal) {
            let obj = {
              1: '12328',
              2: '12345',
              3: '咨询热线',
              4: '其他'
            }
            return obj[dataVal.dataSource]
          }
        }, {
          name: '工单编号',
          key: 'orderNo'
        }, {
          name: '工单类型',
          key: 'orderType',
          format: function (calVal, colObj, dataVal) {
            let obj = {
              1: '投诉',
              2: '咨询',
              3: '建议'
            }
            return obj[dataVal.orderType]
          }
        }, {
          name: '被反映单位',
          key: 'questionCompany'
        }, {
          name: '承办单位',
          key: 'executeCompany'
        }, {
          name: '联系人姓名',
          key: 'name'
        }, {
          name: '联系电话',
          key: 'phone'
        }, {
          name: '是否重复投诉',
          key: 'repeat',
          format: function (calVal, colObj, dataVal) {
            let obj = {
              1: '是',
              0: '否'
            }
            return obj[dataVal.repeat]
          }
        }, {
          name: '平台派单时间',
          key: 'platformTime'
        }, {
          name: '城管委派单时间',
          key: 'urbanTime'
        }, {
          name: '提醒时间',
          key: 'remindTime'
        }, {
          name: '截止时间',
          key: 'cutoffTime'
        }, {
          name: '投诉具体内容',
          key: 'content'
        }
      ],
      complainData: {}
    }
  },
  created() {
    this.getData()
  },
  computed: {
    status: function () {
      let obj = {
        '1': '未处理',
        '2': '跟进中',
        '3': '已办结'
      }
      return obj[this.complainData.complaintStatus]
    }
  },
  methods: {
    getData() {
      this.$axios
        .get(`acb/2.0/customerComplaint/detail/${this.$route.query.complaintId}`)
        .then(res => {
          this.loading = false;
          if (res.state == 0) {
            this.complainData = res.value;
            this.tableData = res.value.listParkRecord
            this.historyData = res.value.listExecute
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        })
    },
    cale() {
      this.$refs.cale.dialogVisible = true;
    }
  }
};
</script>
<style lang="stylus" scoped>
>>>.colName
  text-align center !important
>>>.colValue
  text-align center !important
</style>
